import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useFirestoreConnect } from 'react-redux-firebase';

import ContractorsList from 'views/Admin/Staffing/Contractors/ContractorsList/ContractorsList';
import ContractorDetail from 'views/Admin/Staffing/Contractors/ContractorDetail/ContractorDetail';

import ProjectsList from 'views/Admin/Staffing/Projects/ProjectsList/ProjectsList';
import ProjectDetail from 'views/Admin/Staffing/Projects/ProjectDetail/ProjectDetail';

import SchedulesList from 'views/Admin/Staffing/Schedules/SchedulesList';

import DealsList from './Deals/DealsList';
import DealDetails from './Deals/DealDetail';
import { useSelector } from 'react-redux';

const Staffing = () => {
  useFirestoreConnect(() => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date as 'YYYY-MM-DD'
    return [
      {
        collection: 'contractors',
        storeAs: 'contractors',
      },
      {
        collection: 'projects',
        storeAs: 'projects',
      },
      {
        collection: 'deals',
        storeAs: 'deals',
      },
      {
        collection: 'companies',
        storeAs: 'companies',
      },
      {
        collection: 'projectMethodologies',
        storeAs: 'projectMethodologies',
      },
      {
        collection: 'staffBlockedTimes',
        where: ['endDate', '>=', today],
        storeAs: 'staffBlockedTimes',
      },
    ];
  });

  const user = useSelector(state => state.firebase.profile);

  if (!user.staffingAdmin) {
    return <Redirect to="/admin/dashboard" />;
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path="/admin/staffing/deals"
          render={routeProps => <DealsList {...routeProps} />}
        />
        <Route
          exact
          path="/admin/staffing/projects"
          render={routeProps => <ProjectsList {...routeProps} />}
        />
        <Route
          exact
          path="/admin/staffing/deals/:dealId"
          render={routeProps => <DealDetails {...routeProps} />}
        />
        <Route
          path="/admin/staffing/projects/detail/:projectId"
          render={routeProps => <ProjectDetail {...routeProps} />}
        />

        <Route
          exact
          path="/admin/staffing/contractors"
          render={routeProps => <ContractorsList {...routeProps} />}
        />
        <Route
          path="/admin/staffing/contractors/:contractorId"
          render={routeProps => <ContractorDetail {...routeProps} />}
        />
        <Route
          exact
          path="/admin/staffing/schedules"
          render={routeProps => <SchedulesList {...routeProps} />}
        />
        <Redirect from="/admin/staffing" to="/admin/staffing/deals" />
      </Switch>
    </>
  );
};

export default Staffing;
