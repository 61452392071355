import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

// library components
import { Formik, Form } from 'formik';
import { Button, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import { useConfirm } from 'material-ui-confirm';

//validation library
import * as Yup from 'yup';
import moment from 'moment';
import { some, values, trimEnd, map, includes } from 'lodash';

import {
  PROJECT_CALENDAR_EVENT_RECURRENCE_SELECT_OPTIONS,
  PROJECT_LOCATION_TYPES,
  PROJECT_SERVICE_SELECT_OPTIONS,
  VC_EVENT_REGION_SELECT_OPTIONS,
  VC_EVENT_TOPIC_SELECT_OPTIONS,
  VC_EVENT_TYPES_SELECT_OPTIONS,
} from 'constants/staffing';

import { useStaffingSelector } from 'redux/selectors/staffing/staffingSelectors';
import {
  selectProject,
  selectProjectMethodologies,
  selectDealsDropdown,
} from 'redux/selectors/staffing/getProject';
import {
  createProject,
  createProjectMethodology,
  updateProject,
  setDealsFilterPayload,
} from 'redux/actions/staffingActions';
import { selectDeal } from 'redux/selectors/staffing/getDeals';
import { enqueueErrorSnackbar } from 'redux/actions/notifierActions';

import TextInput from 'components/CustomFormComponents/TextInput';
import MaterialDate from 'components/CustomFormComponents/MaterialDate';
import MaterialTime from 'components/CustomFormComponents/MaterialTime';
import CustomCheckbox from 'components/CustomFormComponents/CustomCheckbox';
import TextArea from 'components/CustomFormComponents/TextArea';
import ButtonSpinner from 'components/ButtonSpinner';
import CustomSelect from 'components/CustomFormComponents/CustomSelect';
import Spinner from 'components/Spinner';

import { PROJECT_LOCATION_TYPES_SELECT_OPTIONS, PROJECT_STATUS_STATES } from 'constants/staffing';
import { formatPrice } from 'utils/formatPrice';
import CustomSearchableSelect from 'components/CustomFormComponents/CustomSearchableSelect';
import AutoSuggestTextInput from 'components/CustomFormComponents/AutoSuggestTextInput';
import SearchableSelect from 'components/SearchableSelect/SearchableSelect';

const useStyles = makeStyles(theme => ({
  // dialogActions: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  //   marginTop: theme.spacing(4),
  // },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '200px', // Adjust this width as needed
  },

  staticInfoViewLabel: {
    // lineHeight: '30px',
    // fontSize: 12,
    // // fontWeight: '300',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  staticInfoView: {
    minHeight: 32,
    border: '1px dashed #C9C9C9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 5,
  },
  staticInfoViewTitle: {
    lineHeight: '30px',
    fontSize: 14,
    fontWeight: '400',
    color: '#5E5E5E',
  },
  addMethodologyButton: {
    height: 30,
    width: 30,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    marginLeft: 0,
    position: 'relative',
    top: 26,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },
  addMethodologyButtonGrey: {
    height: 30,
    width: 30,
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    marginLeft: 0,
    position: 'relative',
    top: 26,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
      color: '#fff',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 6,
  },
  cancelButton: {
    width: 105,
    height: 40,
    marginRight: 'auto',
    background: '#9E9E9E',
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: '#9E9E9E',
      opacity: 0.9,
    },
  },
  saveButton: {
    width: 105,
    height: 40,
    background: theme.palette.primary.main,
    color: '#fff',
    fontSize: 14,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      background: '#9E9E9E',
      color: '#fff',
    },
  },

  inputLabel: {
    // lineHeight: '30px',
    // fontSize: 12,
    // fontWeight: '300',
  },
  selectContainer: {
    marginBottom: 0,
  },

  checkboxLabel: {
    lineHeight: '30px',
    fontSize: 12,
    fontWeight: '300',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
    textAlign: 'right',
    width: '100%',
  },
}));

const AddProjectToDealForm = ({ closeDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const confirm = useConfirm();

  const statusArr = values(PROJECT_STATUS_STATES);

  const [isSubmittingAddProjectMethodology, setIsSubmittingAddProjectMethodology] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAddMethodologyEnabled, setIsAddMethodologyEnabled] = useState(false);

  //Get the Project Methodologies from the Store
  const getAllProjectMethodologies = selectProjectMethodologies();
  const { projectMethodologies } = useSelector(state => getAllProjectMethodologies(state));

  //Get the Deals
  const getAllDealsDropdown = selectDealsDropdown();
  const { deals } = useSelector(state => getAllDealsDropdown(state));

  const { projectToUpdateId, dealToUpdateId } = useStaffingSelector();
  const getProject = selectProject();
  const isUpdate = Boolean(projectToUpdateId);

  //Get the Project Info from the Store
  const project = useSelector(state => getProject(state, projectToUpdateId));

  //Get the Deal Info from the Store
  const getDeal = selectDeal();
  const deal = useSelector(state => getDeal(state, dealToUpdateId));

  // TODO: build this into the deal and project selectors...
  const isPublic = deal?.company?.companyName === 'Voltage Control';

  const defaultCalendarEventRecurrenceType = PROJECT_CALENDAR_EVENT_RECURRENCE_SELECT_OPTIONS.find(
    option => option.value === 'DAILY',
  ).value;

  //Form Initial Values
  const formInitialValues = {
    calendarEventRecurrenceType:
      project?.calendarEventRecurrenceType ?? defaultCalendarEventRecurrenceType,
    contactName: project?.contactName ?? '',
    contactEmail: project?.contactEmail ?? '',
    contractValue: project?.contractValue ?? deal?.amountLeft ?? 0,
    endDate: project?.endDate ?? '',
    endTime: project?.endTime ?? '15:00:00',
    eventName: project?.eventName ?? '',
    eventSubtitle: project?.eventSubtitle ?? '',
    eventRegion: project?.eventRegion ?? '',
    eventTopic: project?.eventTopic ?? '',
    eventType: project?.eventType ?? '',
    isFree: project?.isFree ?? false,
    locationType: project?.locationType ?? '',
    notes: project?.notes ?? '',
    projectMethodologyId: project?.projectMethodologyId ?? '',
    serviceType: project?.serviceType ?? '',
    startDate: project?.startDate ?? '',
    startTime: project?.startTime ?? '10:00:00',
    venue: project?.venue ?? '',
    zoomUserEmail: project?.zoomUserEmail ?? '',
  };

  // get the project status with '-error' stripped off if it is there
  const nonErrorStatus = project?.status
    ? project.status.endsWith('-error')
      ? trimEnd(project.status, '-error')
      : project.status
    : null;

  // const isInProcess = () =>
  //   statusArr.indexOf(nonErrorStatus) > statusArr.indexOf(PROJECT_STATUS_STATES.SCHEDULED);
  const isScheduled = () =>
    statusArr.indexOf(nonErrorStatus) > statusArr.indexOf(PROJECT_STATUS_STATES.ADDING_CALENDAR);
  const dateRequiredWhenScheduled = value =>
    !isUpdate || !isScheduled() || (value && isScheduled());
  const isValidTime = value => !value || (value && moment(value, 'HH:mm:ss').isValid());
  // Define the single validation function outside
  const isValidEndTime = (value, testContext) => {
    // Check if required when scheduled
    if (!dateRequiredWhenScheduled(value)) {
      return testContext.createError({ message: 'Required once the project has been scheduled' });
    }

    // Check if it's a valid time
    if (!isValidTime(value)) {
      return testContext.createError({ message: 'Must be a valid time' });
    }

    return true;
  };

  // Yup schema
  const formSchema = Yup.object().shape({
    locationType: Yup.string().required('Required'),
    contactName: Yup.string(),
    contactEmail: Yup.string().email('Contact Email should be a valid email'),
    zoomUserEmail: Yup.string().when('locationType', {
      is: locationType => locationType !== 'in_person',
      then: Yup.string().required('Required when the location type is Virtual or Hybrid'),
      otherwise: Yup.string().notRequired(),
    }),
    venue: Yup.string().when('locationType', {
      is: locationType => locationType !== 'virtual',
      then: Yup.string().required('Required when the location type is In-Person or Hybrid'),
      otherwise: Yup.string().notRequired(),
    }),
    eventName: Yup.string().required('Required'),
    eventSubtitle: Yup.string(),
    eventRegion: Yup.string().test(
      'required-when-public',
      'Required for public projects',
      value => (isPublic && Boolean(value)) || !isPublic,
    ),
    eventTopic: Yup.string().test(
      'required-when-public',
      'Required for public projects',
      value => (isPublic && Boolean(value)) || !isPublic,
    ),
    eventType: Yup.string().test(
      'required-when-public',
      'Required for public projects',
      value => (isPublic && Boolean(value)) || !isPublic,
    ),
    serviceType: Yup.string()
      .required('Required')
      .test(
        'service-type-validation',
        'Public projects must be either Training, Certification, or Facilitation (for Practice Playground only)',
        function (value) {
          const methodologyId = this.parent.projectMethodologyId;
          const methodology = projectMethodologies.find(m => m.value === methodologyId)?.name;
          return (
            projectToUpdateId ||
            !isPublic ||
            ['Training', 'Certification'].includes(value) ||
            (value === 'Facilitation' && methodology === 'Practice Playground')
          );
        },
      ),
    projectMethodologyId: Yup.string().required('Required'),
    startDate: Yup.date()
      .test(
        'required-when-public',
        'Required to create a public project',
        value => (isPublic && value) || !isPublic,
      )
      .test('required-when-scheduled', 'Required once the project has been scheduled', value =>
        dateRequiredWhenScheduled(value),
      ),
    startTime: Yup.string()
      .test(
        'required-when-public',
        'Required to create a public project',
        value => (isPublic && value) || !isPublic,
      )
      .test('required-when-scheduled', 'Required once the project has been scheduled', value =>
        dateRequiredWhenScheduled(value),
      )
      .test('is-time', 'must be a valid time', value => isValidTime(value))
      .nullable(),
    endDate: Yup.date()
      .min(Yup.ref('startDate'), "End Date can't be set before the Start Date")
      .test('required-when-scheduled', 'Required once the project has been scheduled', value =>
        dateRequiredWhenScheduled(value),
      ),
    endTime: Yup.string().test('end-time-validation', isValidEndTime),
    isFree: Yup.boolean(),
    contractValue: Yup.number()
      .max(
        (deal?.amountLeft || 0) + formInitialValues.contractValue,
        "Must be less than the Deal's remaining estimated value",
      )
      .min(0, 'Must be greater than or equal to 0'),
    notes: Yup.string(),
    calendarEventRecurrenceType: Yup.string().required('Required'),
  });

  const handleFormSubmit = async (values, { setErrors }) => {
    const fieldsToCheck = [
      'locationType',
      'startDate',
      'endDate',
      'startTime',
      'endTime',
      'calendarEventRecurrenceType',
      'venue',
      'zoomUserEmail',
      'eventType',
      'eventTopic',
      'eventRegion',
      'eventSubtitle',
      'eventName',
      'notes',
    ];

    const remindUserToFixProjectFolderName =
      isPublic && projectToUpdateId && values.startDate !== formInitialValues.startDate;

    const changedScheduleFields =
      isScheduled() && projectToUpdateId
        ? fieldsToCheck.filter(field => values[field] !== formInitialValues[field])
        : [];
    const remindUserToRunResyncCalendar = changedScheduleFields.length > 0;

    if (remindUserToFixProjectFolderName || remindUserToRunResyncCalendar) {
      let message = '';
      if (remindUserToFixProjectFolderName) {
        message += "Start date is changed.\nYou'll need to manually rename the project folder.";
      }
      if (remindUserToRunResyncCalendar) {
        if (message) {
          message += '\n\n';
        }
        message += `${toSentence(changedScheduleFields)} ${changedScheduleFields.length > 1 ? 'have' : 'has'} changed.\nYou'll need to run Resync Calendar.`;
      }
      confirm({
        description: message,
      }).then(async () => {
        await handleAddProject(values, { setErrors });
      });
    } else {
      await handleAddProject(values, { setErrors });
    }
  };

  const handleAddProject = async (values, { setErrors }) => {
    setIsSubmitting(true);

    delete values.projectMethodologyName;

    const successCallback = newProjectId => {
      setIsSubmitting(false);
      closeDialog();
      // Navigate to the new project's detail page
      history.push(`/admin/staffing/projects/detail/${newProjectId}`);
    };

    // Check if end time is after start time
    if (values.startDate && values.endDate && values.startTime && values.endTime) {
      const start = moment(`${values.startDate}T${values.startTime}`);
      const end = moment(`${values.endDate}T${values.endTime}`);
      if (start.isSame(end, 'day') && !end.isAfter(start)) {
        setErrors({ endTime: 'End time must be after start time' });
        setIsSubmitting(false);
        return;
      }
    }

    // Check if the event name is unique per deal, with special handling for public events
    const existingEventsWithSameName = map(deal.projects, project => ({
      name: project.eventName,
      startDate: project.startDate,
    })).filter(event => event.name == values.eventName);

    if (!projectToUpdateId && existingEventsWithSameName.length > 0) {
      if (isPublic) {
        // For public events, check if there's another event on same day
        const hasSameDayEvent = existingEventsWithSameName.some(event =>
          moment(event.startDate).isSame(values.startDate, 'day'),
        );

        if (hasSameDayEvent) {
          setErrors({ eventName: 'Event Name for public events must be unique per deal per day' });
          setIsSubmitting(false);
          return;
        }
      } else {
        // For non-public events, any name match is a duplicate
        setErrors({ eventName: 'Event Name must be unique per deal' });
        setIsSubmitting(false);
        return;
      }
    }

    if (values.contactName && !values.contactEmail) {
      setErrors({ contactEmail: 'Contact Email is required when setting Contact Name' });
      setIsSubmitting(false);
      return;
    }

    if (values.contactEmail && !values.contactName) {
      setErrors({ contactName: 'Contact Name is required when setting Contact Name' });
      setIsSubmitting(false);
      return;
    }

    if (projectToUpdateId) {
      //if the DealId has been changed, pass the previousDealId to the updateProject thunk
      const previousDealId = project.dealId !== dealToUpdateId ? project.dealId : '';

      dispatch(
        updateProject(
          projectToUpdateId,
          { ...values, dealId: dealToUpdateId },
          successCallback,
          previousDealId,
        ),
      );
      return;
    } else {
      dispatch(createProject({ ...values, dealId: deal.id }, true, successCallback));
    }
  };

  const handleAddNewMethodologyClick = (methodologyName, setFieldValue) => {
    if (isAddMethodologyEnabled && methodologyName && methodologyName.length > 3) {
      //check if the Methodology name already exists
      if (some(projectMethodologies, { name: methodologyName })) {
        dispatch(
          enqueueErrorSnackbar('Methodology already exists. Please enter another Methodology name'),
        );
        return;
      }

      setIsSubmittingAddProjectMethodology(true);

      // Add Methodology to List
      dispatch(
        createProjectMethodology(methodologyName, methodologyId => {
          setIsAddMethodologyEnabled(false);
          setIsSubmittingAddProjectMethodology(false);
          setFieldValue('projectMethodologyId', methodologyId);
          setFieldValue('projectMethodologyName', '');
        }),
      );
      return;
    }

    setIsAddMethodologyEnabled(true);
  };

  const handleEditDeal = () => {
    dispatch(
      setDealsFilterPayload({
        dealToUpdateId: '',
      }),
    );
  };

  const isDurationLessThanOrEqual24Hours = (startDate, startTime, endDate, endTime) => {
    if (!startDate || !startTime || !endDate || !endTime) return false;

    const start = moment(`${startDate} ${startTime}`);
    const end = moment(`${endDate} ${endTime}`);
    const duration = moment.duration(end.diff(start));

    return duration.asHours() <= 24;
  };

  const toSentence = arr => {
    if (arr.length <= 1) return arr.join('');
    if (arr.length === 2) return arr.join(' and ');
    return arr.slice(0, -1).join(', ') + ', and ' + arr.slice(-1);
  };

  return (
    <Formik
      validationSchema={formSchema}
      initialValues={formInitialValues}
      onSubmit={handleFormSubmit}
    >
      {({ submitForm, resetForm, values, setFieldValue, errors, touched, submitCount }) => (
        <Form style={{ paddingBottom: 20 }}>
          {console.log('AddProjectToDealForm : values : ', values)}
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextInput
                name="eventName"
                label="Project Name"
                className=""
                // Project Name is used in initialize, schedule create assets, so don't allow editing
                disabled={isUpdate}
                required
                additionalStyles={{ label: classes.inputLabel }}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomSearchableSelect
                label="Deal"
                name="dealId"
                customOnChange={(_, value) => {
                  dispatch(
                    setDealsFilterPayload({
                      dealToUpdateId: value.value,
                    }),
                  );
                }}
                placeholder=""
                required
                valueOverride={
                  deal
                    ? {
                        name: `${deal?.dealName} (${deal?.companyName})`,
                        value: deal?.id,
                      }
                    : null
                }
                disableClearable
                disabled={Boolean(projectToUpdateId)}
                options={deals}
                customStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextInput
                name="contactName"
                type="string"
                label="Contact Name"
                helperText="Deal Contact Name is used when empty"
                additionalStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>

            <Grid item xs={5}>
              {isAddMethodologyEnabled ? (
                <TextInput
                  name="projectMethodologyName"
                  type="string"
                  label="Methodology"
                  required
                  additionalStyles={{
                    label: classes.inputLabel,
                  }}
                  onClear={() => {
                    setIsAddMethodologyEnabled(false);
                    setFieldValue('projectMethodologyName', '');
                  }}
                />
              ) : (
                <CustomSelect
                  label="Methodology"
                  name="projectMethodologyId"
                  type="select"
                  placeholder=""
                  required
                  selectOptions={projectMethodologies}
                  customStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  disabled={isUpdate}
                />
              )}
            </Grid>

            <Grid item xs={1} container className="pl-0 justify-content-end">
              <Button
                size="small"
                variant="text"
                disabled={isSubmittingAddProjectMethodology || isUpdate}
                className={cx({
                  // 'mt-4': !isAddMethodologyEnabled,
                  // 'mt-5': isAddMethodologyEnabled,
                  [classes.addMethodologyButtonGrey]:
                    isAddMethodologyEnabled && (values.projectMethodologyName || '').length < 3,
                  [classes.addMethodologyButton]:
                    (values.projectMethodologyName || '').length >= 3 || !isAddMethodologyEnabled,
                })}
                onClick={() => {
                  handleAddNewMethodologyClick(values.projectMethodologyName, setFieldValue);
                }}
              >
                {isAddMethodologyEnabled ? (
                  isSubmittingAddProjectMethodology ? (
                    <Spinner size={24} />
                  ) : (
                    <DoneIcon />
                  )
                ) : (
                  <AddIcon />
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextInput
                name="contactEmail"
                type="string"
                label="Contact Email"
                helperText="Deal Contact Email is used when empty"
                additionalStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>
            <Grid item xs={6} className="pr-1">
              <CustomSelect
                label="Service Type"
                name="serviceType"
                type="select"
                placeholder=""
                required
                disabled={isUpdate}
                selectOptions={PROJECT_SERVICE_SELECT_OPTIONS}
                customStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>
          </Grid>

          {isPublic && (
            <>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <CustomSearchableSelect
                    label="Event Topic"
                    className="mb-4"
                    name="eventTopic"
                    type="select"
                    placeholder=""
                    required={isPublic}
                    disableClearable
                    options={VC_EVENT_TOPIC_SELECT_OPTIONS}
                    customStyles={{
                      container: classes.selectContainer,
                      label: classes.inputLabel,
                    }}
                  />
                </Grid>

                <Grid item xs={6} container spacing={2}>
                  <Grid item xs={6}>
                    <CustomSearchableSelect
                      label="Event Type"
                      className="mb-4"
                      name="eventType"
                      type="select"
                      placeholder=""
                      disableClearable
                      required={isPublic}
                      options={VC_EVENT_TYPES_SELECT_OPTIONS}
                      customStyles={{
                        container: classes.selectContainer,
                        label: classes.inputLabel,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomSearchableSelect
                      label="Event Region"
                      className="mb-4"
                      name="eventRegion"
                      type="select"
                      placeholder=""
                      required={isPublic}
                      disableClearable
                      options={VC_EVENT_REGION_SELECT_OPTIONS}
                      customStyles={{
                        container: classes.selectContainer,
                        label: classes.inputLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          <Grid container spacing={5}>
            <Grid item xs={6}>
              <CustomSelect
                label="Location Type"
                className="mb-0"
                name="locationType"
                type="select"
                placeholder=""
                required
                selectOptions={PROJECT_LOCATION_TYPES_SELECT_OPTIONS}
                customStyles={{
                  container: classes.selectContainer,
                  label: classes.inputLabel,
                }}
              />
            </Grid>

            <Grid item xs={6} container spacing={2}>
              <Grid item xs={6}>
                <MaterialDate
                  name="startDate"
                  label="Start Date"
                  allowKeyboardInput={true}
                  className="mb-0"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  required={isPublic || isScheduled()}
                />
              </Grid>

              <Grid item xs={6}>
                <MaterialDate
                  name="endDate"
                  label="End Date"
                  allowKeyboardInput={true}
                  className="mb-0"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  required={isPublic || isScheduled()}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              {[PROJECT_LOCATION_TYPES.hybrid, PROJECT_LOCATION_TYPES.virtual].includes(
                values.locationType,
              ) && (
                <TextInput
                  name="zoomUserEmail"
                  type="string"
                  label="Zoom User Email"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  required={isScheduled()}
                />
              )}
            </Grid>

            <Grid item xs={6} container spacing={2}>
              <Grid item xs={6}>
                <MaterialTime
                  name="startTime"
                  label="Start Time"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  required={isPublic || isScheduled()}
                />
              </Grid>

              <Grid item xs={6}>
                <MaterialTime
                  name="endTime"
                  label="End Time"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                  required={isPublic || isScheduled()}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item xs={6}>
              {[PROJECT_LOCATION_TYPES.hybrid, PROJECT_LOCATION_TYPES.in_person].includes(
                values.locationType,
              ) && (
                <TextInput
                  name="venue"
                  // type="string"
                  label="Venue"
                  additionalStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                />
              )}
            </Grid>

            <Grid item xs={6} container spacing={2}>
              <Grid item xs={6}>
                <div>
                  <Typography className={cx(classes.checkboxLabel, 'mb-1')}>Free</Typography>
                  <CustomCheckbox name="isFree" label="" />
                </div>
              </Grid>
              {!values.isFree && (
                <>
                  <Grid item xs={6}>
                    <TextInput
                      name="contractValue"
                      type="number"
                      label="Amount"
                      className="mb-1"
                      inputProps={{
                        max: deal?.amountLeft ?? 999999,
                        min: 0,
                      }}
                      required
                      additionalStyles={{
                        container: classes.selectContainer,
                        label: classes.inputLabel,
                      }}
                    />
                    {project ? (
                      <Typography className={cx(classes.inputLabel)}>
                        {deal
                          ? `${formatPrice(deal.amountLeft + formInitialValues.contractValue)}`
                          : '$0'}{' '}
                        remaining
                      </Typography>
                    ) : (
                      <Typography className={cx(classes.inputLabel)}>
                        {deal ? `${formatPrice(deal.amountLeft)}` : '$0'} remaining
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          {isPublic && (
            <TextInput
              name="eventSubtitle"
              label="Sub Title"
              className=""
              additionalStyles={{ label: classes.inputLabel }}
            />
          )}

          <TextArea
            name="notes"
            type="text"
            label="Description"
            multiline
            fullWidth
            rows={4}
            className="mb-4"
            additionalStyles={{ label: classes.inputLabel }}
          />

          {!isDurationLessThanOrEqual24Hours(
            values.startDate,
            values.startTime,
            values.endDate,
            values.endTime,
          ) && (
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <CustomSelect
                  label="Calendar Event Recurrence"
                  className="mb-0"
                  name="calendarEventRecurrenceType"
                  type="select"
                  placeholder=""
                  required
                  selectOptions={PROJECT_CALENDAR_EVENT_RECURRENCE_SELECT_OPTIONS}
                  customStyles={{
                    container: classes.selectContainer,
                    label: classes.inputLabel,
                  }}
                />
              </Grid>
            </Grid>
          )}

          <div className={classes.dialogActions}>
            <Button
              className={classes.cancelButton}
              disabled={isSubmitting}
              onClick={() => {
                closeDialog();
                resetForm();
              }}
            >
              CANCEL
            </Button>
            <div className={classes.saveButtonContainer}>
              <Button
                className={classes.saveButton}
                disabled={isSubmitting || !deal}
                onClick={submitForm}
              >
                SAVE
                {isSubmitting && <ButtonSpinner />}
              </Button>
              {submitCount > 0 &&
                Object.keys(errors).length > 0 &&
                Object.keys(touched).length > 0 && (
                  <Typography color="error" className={classes.errorMessage}>
                    See errors above.
                  </Typography>
                )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

AddProjectToDealForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default AddProjectToDealForm;
